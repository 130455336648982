import React from "react";
import { Layout, Seo } from "components/common";
import Folio from '../components/common/folioLayout/folio'
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"

export default class Urban extends React.Component {
    render() {

        return (
            <>
                <div class="gre">
                    <Layout>
                        <Seo />
                        <Folio pageTitle="Urban Pixls">
                            {/* <Grid> */}
                            <div class="row">
                                <div class="column">
                                    <StaticImage src='../images/urban/1.JPG' layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/2.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/3.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/12.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/13.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/21.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/22.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/23.JPG" layout="constrained" alt="Sample photo" />
                                </div>
                                <div class="column">
                                    <StaticImage src="../images/urban/4.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/5.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/14.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/15.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/20.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/24.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/25.JPG" layout="constrained" alt="Sample photo" />
                                </div>
                                <div class="column">
                                    <StaticImage src='../images/urban/6.JPG' layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/7.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/8.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/16.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/17.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/28.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/29.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/26.JPG" layout="constrained" alt="Sample photo" />
                                </div>
                                <div class="column">
                                    <StaticImage src="../images/urban/9.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/10.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/11.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/18.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/19.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/30.JPG" layout="constrained" alt="Sample photo" />
                                    <StaticImage src="../images/urban/31.JPG" layout="constrained" alt="Sample photo" />
                                </div>
                            </div>
                            {/* </Grid> */}
                        </Folio>
                    </Layout>
                </div>
            </>
        );
    };
}


// export default Urban;

// import React from "react";
// import { Layout } from "components/common/Layout";
// import { Intro, Skills, Contact, Projects } from "components/landing";
// // import { Grid } from "../components/landing/Projects/styles"
// import Folio from '../components/common/folioLayout/folio'
// // import "./style.css"
// import Lightbox from '../components/common/folioLayout/lightbox'
// import Gallery from '../components/common/folioLayout/gallery'
// import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
// import { Link, graphql } from "gatsby"
// import { node } from "prop-types";
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
// import thumbnails from "components/common/folioLayout/thumbnails";
// const Urban = ({ data }) => {



//     return (
//         <>
//             <div class="gre">
//                 <Layout>
//                     <Folio pageTitle="Urban Jungle">
//                         {/* <SimpleReactLightbox>
//                             <SRLWrapper>

//                                 {data.urban.edges.map(({ node }) => (
//                                     <p key={node.id}>
//                                         <a href={node.publicURL}>
//                                             <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split(".")[0]} />
//                                         </a>
//                                     </p>

//                                 ))}
//                             </SRLWrapper>
//                         </SimpleReactLightbox> */}
//                         {/* <SimpleReactLightbox> */}
//                         {/* <SRLWrapper options={options}> */}
//                         {/* <Gallery />
//                                  */}
//                         {data.urban.edges.map(({ node }) => (
//                             <p key={node.id}>
//                                 <a href={node.publicURL}>
//                                     <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split(".")[0]} />
//                                 </a>
//                             </p>

//                         ))}
//                         {/* </SRLWrapper> */}
//                         {/* </SimpleReactLightbox> */}
//                     </Folio>
//                 </Layout>
//             </div>
//         </>
//     );
// }


// export default Urban;

// export const pageQuery = graphql`
// query {
//     urban: allFile(filter: {absolutePath: {regex: "/urban/"}}) {
//         edges {
//           node {
//             id
//             base
//             publicURL
//             childImageSharp {
//               gatsbyImageData(
//                 height: 600
//                 width: 900
//                 transformOptions: {fit: COVER}
//                 placeholder: DOMINANT_COLOR
//                 webpOptions: {quality: 10}
//               )
//             }
//           }
//         }
//       }
// }
// `